body {
  padding-top: 2.8rem;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #c5c5c5 !important;
  color: #313131 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursorHand {
  cursor: pointer;
}

.MuiInputBase-input {
  color: #313131 !important;
}

.MuiSelect-icon {
  color: #313131 !important;
}

.MuiSvgIcon-root {
  color: #2b2828 !important;
}

.checkBoxTabel>span .MuiSvgIcon-root {
  color: #e72931 !important;
}

.basic-navbar-nav {
  color: #313131 !important;
}

input {
  color: #313131 !important;
}

.MuiCheckbox-root {
  color: #313131 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #e72931 !important;
}

textarea {
  color: #313131 !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #313131 !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #e72931 !important;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
  background-color: #e72931  !important;
  color: #ffffff !important;
}

fieldset>legend {
  color: #313131 !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #313131;
  -webkit-box-shadow: 0 0 0px 1000px #c5c5c5 inset;
  transition: background-color 5000s ease-in-out 0s;
}


.checkBox {
  color: #313131 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #313131 !important;

}

label {
  color: #313131 !important;
}

.label-black {
  color: rgba(0, 0, 0, 0.87) !important;
}

.input-black>div>input {
  color: rgba(0, 0, 0, 0.87) !important;
}

.paper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.avatar {
  margin: 8px;
  background-color: rgb(231, 41, 49) !important;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px !important;
  background-color: rgb(231, 41, 49) !important;
  color: #fff !important;
}

a {
  color: #e6e6e6 !important;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 10px 0 10px;
  z-index: 9999;
}

/* khusus ipos */

.bg-dark {
  background-color: #e72931 !important;
}

.colorLogin {
  color: #ff0000 !important;
}

/* ..........batas.......... */

.btnBack{
  cursor: pointer;
  color: #e72931;
}
.btnTable {
  padding: 11px 15px;
}
.btnback{
  cursor: pointer;
}
.MuiAppBar-colorPrimary {
  background-color: #e72931 !important;
}

.colorAktif{
  color: #e72931 !important;
}
.sizeAktifMap{
  font-size: 16px  !important;;
}

.buttonExportExcel {
  color: #fff;
  background-color: #e72931;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
}