.cardHolder{
  margin: 30px 0px 30px;
}

.cardTemplate {
  overflow: visible !important;
  padding: 0px 10px;
}
.cardIcon {
  float: left;
  margin-top: -30px;
  padding: 15px;
  color:#212121;
}

.cardTitle{
  padding-top: 10px;
  font-size: 16px;
}

.cardValue{
  padding-top:20px;
}

.cardValueBase{

}

.cardValueChanging{
  animation: valueChanged 1.5s;
}

@keyframes valueChanged{
  from {opacity: 0.01}
  tp {opacity:1}
}