table > tbody > tr > td > a {
  color:rgba(0, 0, 0, 0.87) !important;
}
.tabOutlet{
  color: black !important;
}
.tabOutletActive{
  background-color: rgb(231, 41, 49) !important;
  color: #fff !important;
}    
.MuiDialog-paperWidthSm{
  min-width: 400px !important;
}  